import Header from '../../components/Header';
import StarIcon from '../../assets/icons/star.png';
import Ball from '../../assets/images/ball.png';
// @ts-ignore
import Video from '../../assets/videos/video.mp4';
import VideoPoster from '../../assets/images/video.png';

import './index.scss';
import Footer from '../../components/Footer';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import HightLineIcon1 from '../../assets/images/hightline_1.png';
import HightLineIcon2 from '../../assets/images/hightline_2.png';
import HightLineIcon3 from '../../assets/images/hightline_3.png';
import Background from '../../assets/images/background.png';
// import ReactPageScroller, { SectionContainer } from 'react-page-scroller';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const HomePage = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      anchorPlacement: 'bottom-center',
      mirror: true,
      once: true,
      easing: 'ease-in-out',
      delay: 300,
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="home">
        <Header type="" />

        <img className="home-background" src={Background} alt="background" />

        <section className="home-main">
          <div className="home-main-contents">
            <div className="home-main-contents-sub" data-aos="fade-left" data-aos-delay="100">
              Making Web3 Accessible to Everyone
            </div>
            <div className="home-main-contents-slogen" data-aos="fade-left" data-aos-delay="300">
              The Agent Gateway for
            </div>
            <div className="home-main-contents-slogen small" data-aos="fade-left" data-aos-delay="600">
              Intent-Centric Web3 Experiences
            </div>
            <div
              className="home-main-contents-btn hover-animate"
              data-aos="fade-left"
              data-aos-delay="900"
              onClick={() => {
                navigate('/joinwaitlist');
              }}
            >
              <img src={StarIcon} alt="icon" />
              Join Waitlist
            </div>
          </div>
          <img className="home-main-ball" src={Ball} alt="ball" />
        </section>
        <section className="home-video">
          <div className="home-video-wrap">
            <ReactPlayer url={Video} poster={VideoPoster} playsinline controls width="100%" height="100%" />
          </div>
        </section>
        <section className="home-features">
          <div className="home-features-title">Project Highlights</div>
          <div className="home-features-wrap">
            <div className="home-features-wrap-item" data-aos="fade-left" dayarnta-aos-delay="100">
              <img className="home-features-wrap-item-icon" src={HightLineIcon1} alt="icon" />
              <div className="home-features-wrap-item-title">Intent-Centric User Experiences</div>
              <div className="home-features-wrap-item-desc">
                The platform's design philosophy prioritizes intent-centric experiences, ensuring that interactions with
                decentralized protocols are not only user-friendly but also highly tailored to individual needs and
                preferences. By leveraging AI to understand and anticipate user intentions, the platform creates a
                personalized pathway through the crypto landscape, enhancing user satisfaction and engagement.
              </div>
            </div>
            <div className="home-features-wrap-item" data-aos="fade-left" data-aos-delay="500">
              <img className="home-features-wrap-item-icon" src={HightLineIcon2} alt="icon" />
              <div className="home-features-wrap-item-title">AI-Driven Protocols Integration</div>
              <div className="home-features-wrap-item-desc">
                This integration enables seamless access to a wide range of dApps, fostering an intuitive and efficient
                user experience. The platform's focus on reducing entry barriers to decentralized technologies
                emphasizes its role in making the innovative potential of blockchain more accessible to a broader
                audience, thereby accelerating the adoption of Web3 applications.
              </div>
            </div>
            <div className="home-features-wrap-item" data-aos="fade-left" data-aos-delay="900">
              <img className="home-features-wrap-item-icon" src={HightLineIcon3} alt="icon" />
              <div className="home-features-wrap-item-title">Model and Agent Architecture</div>
              <div className="home-features-wrap-item-desc">
                The project utilizes an Autonomous-Perception-Execution (APE) Large Language & Multimodal Model, which
                integrates self-reflection, evaluation, trajectory (short-term memory), and experience (long-term
                memory) functionalities. The agent architecture is designed to autonomously interoperate with data and
                the environment, handling web static and dynamic GUIs, heterogeneous APIs, and smart contract ABIs.
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
