import './index.scss';
import BackgroundImage from '../../assets/images/background_cases.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import MobileHeader from '../../components/MobileHeader';

const MobileCases = (): JSX.Element => {
  useEffect(() => {
    AOS.init({
      anchorPlacement: 'bottom-center',
      mirror: true,
      once: true,
      easing: 'ease-in-out',
      delay: 300,
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="mobile-cases">
        <MobileHeader />
        <img className="mobile-cases-background" src={BackgroundImage} alt="background" />
        <div className="mobile-cases-wrap">
          <div className="mobile-cases-title" data-aos="fade-left" data-aos-delay="100">
            How customers use AutoAPE.AI
          </div>
          <div className="mobile-cases-sub" data-aos="fade-left" data-aos-delay="500">
            See how your team get the most out of intelligent Web3 with Agent
          </div>
          <div className="mobile-cases-infos">
            <div className="mobile-cases-infos-wrap">
              <div className="mobile-cases-infos-item" data-aos="fade-left" data-aos-delay="900">
                <div className="mobile-cases-infos-item-title">Newcomer's Companion:</div>
                <div className="mobile-cases-infos-item-sub">Your Personal Guide to Crypto</div>
                <div className="mobile-cases-infos-item-content">
                  AutoAPE.AI is the perfect companion for those new to Web3, offering a simple and intuitive interface
                  for essential crypto transactions. Swap between various tokens, effortlessly buy stable coins for a
                  steady investment, and securely purchase your favorite tokens with AutoAPE.AI's smart contract
                  assistance.
                </div>
              </div>
              <div className="mobile-cases-infos-item" data-aos="fade-left" data-aos-delay="1000">
                <div className="mobile-cases-infos-item-title">Trader's Edge:</div>
                <div className="mobile-cases-infos-item-sub">Master the Markets with AI-Powered Trading</div>
                <div className="mobile-cases-infos-item-content">
                  AutoAPE.AI elevates trading to new heights with multi-pool trading, one-click staking, and automated
                  restaking for maximized liquidity and minimized slippage. Our AI agents analyze market trends to
                  suggest optimal trading paths, enhancing your trading efficiency and profitability.
                </div>
              </div>
              <div className="mobile-cases-infos-item" data-aos="fade-left" data-aos-delay="1100">
                <div className="mobile-cases-infos-item-title">Liquidity Mastery:</div>
                <div className="mobile-cases-infos-item-sub">Sophisticated Strategies for Institutions</div>
                <div className="mobile-cases-infos-item-content">
                  AutoAPE.AI provides a cutting-edge liquidity management solution, offering real-time position
                  monitoring, automated portfolio rebalancing, and yield optimization through arbitrage opportunities.
                  This AI-driven approach ensures your investment strategy stays aligned with your goals and risk
                  profile, 24/7.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileCases;
