import axiosInstance from './axios';

export const joinWaitlist = async (name: string, emailAddress: string, title: string) => {
  const response = await axiosInstance.post(
    'https://oan17vf77a.execute-api.us-east-1.amazonaws.com/default/AutoAPEWebWaitList',
    {
      data: {
        type: 1,
        name,
        email_address: emailAddress,
        title,
      },
    }
  );
  return response.data;
};

export const notify = async (emailAddress: string) => {
  const response = await axiosInstance.post(
    'https://oan17vf77a.execute-api.us-east-1.amazonaws.com/default/AutoAPEWebWaitList',
    {
      data: {
        type: 2,
        email_address: emailAddress,
      },
    }
  );
  return response.data;
};
