import MobileHeader from '../../components/MobileHeader';
import './index.scss';
import BackgroundImage from '../../assets/images/background_cases.png';
import { InlineWidget } from 'react-calendly';

const MobileCalendly = (): JSX.Element => {
  return (
    <>
      <div className="mobile-calendly">
        <MobileHeader />
        <img className="calendly-wrap-background" src={BackgroundImage} alt="background" />
        <div className="calendly-wrap-container">
          <InlineWidget url="https://calendly.com/chloe_autoape/30min" />
        </div>
      </div>
    </>
  );
};

export default MobileCalendly;
