import axios, { AxiosInstance, AxiosResponse } from 'axios';

// const baseURL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1' : 'https://your.domain.com/api';
const timeout = 30000;
const axiosInstance: AxiosInstance = axios.create({
  timeout,
  headers: {
    'Access-Control-Allow-Credentials': true,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // 对响应数据做点什么
    return response;
  },
  (error) => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

export default axiosInstance;
