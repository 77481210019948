import { useRef, useState } from 'react';
import './index.scss';
import toast, { Toaster } from 'react-hot-toast';
import { joinWaitlist } from '../../utils/api';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingData from '../../assets/images/loading.json';
import BackgroundImage from '../../assets/images/mobile_background_join.png';
import MobileHeader from '../../components/MobileHeader';

const MobileWaitlist = (): JSX.Element => {
  var nameRef = useRef<HTMLInputElement>(null);
  var emailRef = useRef<HTMLInputElement>(null);
  var titleRef = useRef<HTMLTextAreaElement>(null);

  const [isShowLoading, setIsShowLoading] = useState(false);

  const onJoin = async () => {
    if (isShowLoading) {
      return;
    }
    // @ts-ignore
    const name = nameRef.current.value;
    // @ts-ignore
    const email = emailRef.current.value;
    // @ts-ignore
    const title = titleRef.current.value;

    if (!name.trim()) {
      toast.error('Name can not be empty.');
      return;
    }
    if (!email.trim()) {
      toast.error('Email can not be empty.');
      return;
    }
    if (!title.trim()) {
      toast.error('Tittle @ Company can not be empty.');
      return;
    }

    const reg = new RegExp(/^[A-Za-z0-9-_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
    if (!reg.test(email.trim())) {
      toast.error('Email validate failed.');
      return;
    }

    setIsShowLoading(true);
    try {
      await joinWaitlist(name.trim(), email.trim(), title.trim());
      toast.success('Successful!');
    } catch (error) {
      console.log('%c⧭', 'color: #00e600', error);
      toast.error('Bad Network.');
    } finally {
      setIsShowLoading(false);
    }
  };

  return (
    <>
      <div className="mobile-waitlist">
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              fontSize: '15px',
              minWidth: '200px',
              minHeight: '56px',
              padding: '4px 8px',
              wordBreak: 'break-all',
              fontFamily: 'HarmonyOS Sans SC',
            },
          }}
        />

        {isShowLoading && (
          <div className="overlay">
            <Player autoplay loop src={LoadingData} style={{ height: '300px', width: '300px' }}></Player>
          </div>
        )}

        <MobileHeader />

        <img className="mobile-waitlist-background" src={BackgroundImage} alt="background" />

        <div className="mobile-waitlist-wrap">
          <div className="mobile-waitlist-title">Join Waitlist</div>
          <div className="mobile-waitlist-sub">
            Be the first to try AutoAPE.AI
            <br />
            Stay informed about our progress by receiving email
            <br />
            notifications about our next steps!
          </div>
          <input className="mobile-waitlist-input" placeholder="*Name" type="text" ref={nameRef} />
          <input className="mobile-waitlist-input" placeholder="*Email" type="text" ref={emailRef} />
          <textarea className="mobile-waitlist-input" placeholder="*Tittle @ Company" ref={titleRef} />
          <div className="mobile-waitlist-btn hover-animate" onClick={onJoin}>
            Join Now
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileWaitlist;
