import Logo from '../../assets/images/logo.png';
// import JumpIcon from '../../assets/icons/jump.png';

import './index.scss';
import { useNavigate } from 'react-router-dom';

const Header = ({ type }: { type: string }): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <header className="header">
        <a href="/">
          <img className="header-logo" src={Logo} alt="logo" />
        </a>
        <nav className="header-nav">
          <a href="/usecases">
            <div className={`header-nav-item hover-animate ${type === 'cases' ? 'active' : ''}`}>Use Cases</div>
          </a>
          <a href="/app">
            <div className={`header-nav-item hover-animate ${type === 'app' ? 'active' : ''}`}>App</div>
          </a>
          <div
            className="header-nav-demo hover-animate"
            onClick={() => {
              navigate('/calendly');
            }}
          >
            Book a Demo
            {/* <img src={JumpIcon} alt="icon" /> */}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
