import { useState } from 'react';
import './index.scss';
import MenuIcon from '../../assets/icons/menu.png';
import CloseIcon from '../../assets/icons/close.png';
import { useNavigate } from 'react-router-dom';

const MobileHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <header className="mobile-header">
        {isOpen ? (
          <img
            className="mobile-header-close"
            src={CloseIcon}
            alt="icon"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        ) : (
          <img
            className="mobile-header-menu"
            src={MenuIcon}
            alt="icon"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        )}
        <div
          className="mobile-header-btn"
          onClick={() => {
            navigate('/calendly');
          }}
        >
          Book a Demo
        </div>
      </header>

      <div className={`menu-wrap ${isOpen ? 'active' : ''}`}>
        <nav className="menu-wrap-nav">
          <a href="/">
            <div className={`menu-wrap-nav-item hover-animate`}>Home</div>
          </a>
          <a href="/usecases">
            <div className={`menu-wrap-nav-item hover-animate`}>Use Cases</div>
          </a>
          <a href="/app">
            <div className={`menu-wrap-nav-item hover-animate`}>App</div>
          </a>
        </nav>
      </div>
    </>
  );
};

export default MobileHeader;
