import './index.scss';
import Background from '../../assets/images/background.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Aos from 'aos';
import StarIcon from '../../assets/icons/star.png';
import Ball from '../../assets/images/mobile_ball.png';
// @ts-ignore
import Video from '../../assets/videos/video.mp4';
import VideoPoster from '../../assets/images/video.png';
import ReactPlayer from 'react-player';
import HightLineIcon1 from '../../assets/images/hightline_1.png';
import HightLineIcon2 from '../../assets/images/hightline_2.png';
import HightLineIcon3 from '../../assets/images/hightline_3.png';
import MobileFooter from '../../components/MobileFooter';
import MobileHeader from '../../components/MobileHeader';

const MobileHome = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({
      anchorPlacement: 'bottom-center',
      mirror: true,
      once: true,
      easing: 'ease-in-out',
      delay: 300,
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="mobile-home">
        <MobileHeader />

        <img className="mobile-home-background" src={Background} alt="background" />

        <section>
          <div className="mobile-home-main">
            <div className="mobile-home-main-sub" data-aos="fade-left" data-aos-delay="100">
              Making Web3 Accessible to Everyone
            </div>
            <div className="mobile-home-main-title" data-aos="fade-left" data-aos-delay="300">
              The Agent Gateway for
              <br />
              <span>Intent-Centric Web3 Experiences</span>
            </div>
            <div className=""></div>
            <div
              className="mobile-home-main-btn hover-animate"
              data-aos="fade-left"
              data-aos-delay="900"
              onClick={() => {
                navigate('/joinwaitlist');
              }}
            >
              <img src={StarIcon} alt="icon" />
              Join Waitlist
            </div>
            <img className="mobile-home-main-boll" src={Ball} alt="ball" />
          </div>
        </section>
        <section>
          <div className="mobile-home-video">
            <div className="mobile-home-video-wrap">
              <ReactPlayer
                url={Video}
                poster={VideoPoster}
                playing={true}
                muted
                playsinline
                controls
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="mobile-home-features">
            <div className="mobile-home-features-title">Project Highlights</div>
            <div className="mobile-home-features-infos">
              <div className="mobile-home-features-infos-wrap">
                <div className="mobile-home-features-infos-wrap-item" data-aos="fade-left" dayarnta-aos-delay="100">
                  <img className="mobile-home-features-infos-wrap-item-icon" src={HightLineIcon1} alt="icon" />
                  <div className="mobile-home-features-infos-wrap-item-title">
                    Intent-Centric User
                    <br />
                    Experiences
                  </div>
                  <div className="mobile-home-features-infos-wrap-item-desc">
                    The platform's design philosophy prioritizes intent-centric experiences, ensuring that interactions
                    with decentralized protocols are not only user-friendly but also highly tailored to individual needs
                    and preferences. By leveraging AI to understand and anticipate user intentions, the platform creates
                    a personalized pathway through the crypto landscape, enhancing user satisfaction and engagement.
                  </div>
                </div>
                <div className="mobile-home-features-infos-wrap-item" data-aos="fade-left" data-aos-delay="500">
                  <img className="mobile-home-features-infos-wrap-item-icon" src={HightLineIcon2} alt="icon" />
                  <div className="mobile-home-features-infos-wrap-item-title">
                    AI-Driven Decentralized
                    <br />
                    Protocols Integration
                  </div>
                  <div className="mobile-home-features-infos-wrap-item-desc">
                    This integration enables seamless access to a wide range of dApps, fostering an intuitive and
                    efficient user experience. The platform's focus on reducing entry barriers to decentralized
                    technologies emphasizes its role in making the innovative potential of blockchain more accessible to
                    a broader audience, thereby accelerating the adoption of Web3 applications.
                  </div>
                </div>
                <div className="mobile-home-features-infos-wrap-item" data-aos="fade-left" data-aos-delay="900">
                  <img className="mobile-home-features-infos-wrap-item-icon" src={HightLineIcon3} alt="icon" />
                  <div className="mobile-home-features-infos-wrap-item-title">
                    Model and Agent
                    <br />
                    Architecture
                  </div>
                  <div className="mobile-home-features-infos-wrap-item-desc">
                    The project utilizes an Autonomous-Perception-Execution (APE) Large Language & Multimodal Model,
                    which integrates self-reflection, evaluation, trajectory (short-term memory), and experience
                    (long-term memory) functionalities. The agent architecture is designed to autonomously interoperate
                    with data and the environment, handling web static and dynamic GUIs, heterogeneous APIs, and smart
                    contract ABIs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <MobileFooter />
      </div>
    </>
  );
};

export default MobileHome;
