import Logo from '../../assets/images/logo.png';

import './index.scss';

const Footer = (): JSX.Element => {
  return (
    <>
      <footer className="footer">
        <div className="footer-wrap">
          <div className="footer-rights">© 2024 AutoAPE.AI ALL RIGHTS RESERVED.</div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Logo} alt="logo" />
          </div>
          <div className="footer-links">
            <a className="footer-links-policy hover-animate" href="/" target="_blank">
              Privacy Policy
            </a>
            <a className="footer-links-email hover-animate" href="mailto:contactus@autoape.ai">
              Email Us
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
