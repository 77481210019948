import { InlineWidget } from 'react-calendly';
import BackgroundImage from '../../assets/images/background_join.png';
import './index.scss';
import Header from '../../components/Header';

const CalendlyPage = (): JSX.Element => {
  return (
    <>
      <div className="calendly-wrap">
        <Header type="" />
        <img className="calendly-wrap-background" src={BackgroundImage} alt="background" />
        <div className="calendly-wrap-container">
          <InlineWidget
            styles={{ minWidth: '350px', height: '750px' }}
            url="https://calendly.com/chloe_autoape/30min"
          />
        </div>
      </div>
    </>
  );
};

export default CalendlyPage;
