import './index.scss';
import Logo from '../../assets/images/logo.png';

const MobileFooter = (): JSX.Element => {
  return (
    <>
      <div className="mobile-footer">
        <img className="mobile-footer-logo" src={Logo} alt="logo" />
        <div className="mobile-footer-right">© 2024 AutoAPE.AI ALL RIGHTS RESERVED.</div>
      </div>
    </>
  );
};

export default MobileFooter;
