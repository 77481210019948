import MobileHeader from '../../components/MobileHeader';
import './index.scss';
import Background from '../../assets/images/background.png';
import { useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Aos from 'aos';
import { notify } from '../../utils/api';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingData from '../../assets/images/loading.json';

const MobileApp = (): JSX.Element => {
  var emailRef = useRef<HTMLInputElement>(null);

  const [isShowLoading, setIsShowLoading] = useState(false);

  const onNotify = async () => {
    if (isShowLoading) {
      return;
    }
    // @ts-ignore
    const email = emailRef.current.value;

    if (!email.trim()) {
      toast.error('Email can not be empty.');
      return;
    }

    const reg = new RegExp(/^[A-Za-z0-9-_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
    if (!reg.test(email.trim())) {
      toast.error('Email validate failed.');
      return;
    }

    setIsShowLoading(true);
    try {
      await notify(email.trim());
      toast.success('Successful!');
    } catch (error) {
      console.log('%c⧭', 'color: #00e600', error);
      toast.error('Bad Network.');
    } finally {
      setIsShowLoading(false);
    }
  };

  useEffect(() => {
    Aos.init({
      anchorPlacement: 'bottom-center',
      mirror: true,
      once: true,
      easing: 'ease-in-out',
      delay: 300,
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="mobile-app">
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              fontSize: '15px',
              minWidth: '200px',
              minHeight: '56px',
              padding: '4px 8px',
              wordBreak: 'break-all',
              fontFamily: 'HarmonyOS Sans SC',
            },
          }}
        />

        {isShowLoading && (
          <div className="overlay">
            <Player autoplay loop src={LoadingData} style={{ height: '300px', width: '300px' }}></Player>
          </div>
        )}
        <img className="mobile-app-background" src={Background} alt="background" />
        <MobileHeader />
        <div className="mobile-app-wrap">
          <div className="mobile-app-wrap-notify">
            <div className="mobile-app-wrap-notify-title" data-aos="fade-left" data-aos-delay="100">
              Agent that helps you with Web3 Intents
            </div>
            <div className="mobile-app-wrap-notify-sub" data-aos="fade-left" data-aos-delay="600">
              Our AutoAPE.AI platform utilizes advanced AI Agents to streamline
              <br />
              everyday interactions with Web3,
              <br />
              making them more intuitive, efficient, and user-friendly.
              <br />
              <span>Available on iOS and Google Play in April 2024.</span>
            </div>
            <div className="mobile-app-wrap-notify-input" data-aos="fade-in" data-aos-delay="900">
              <input placeholder="*Email" type="text" ref={emailRef} />
              <div className="mobile-app-wrap-notify-input-btn hover-animate" onClick={onNotify}>
                Notify
              </div>
            </div>
          </div>
          <div className="mobile-app-wrap-roadmap">
            <div className="mobile-app-wrap-roadmap-title">Roadmap</div>
            <div className="mobile-app-wrap-roadmap-container">
              <div className="mobile-app-wrap-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                <div className="mobile-app-wrap-roadmap-container-item-no">01</div>
                <div className="mobile-app-wrap-roadmap-container-item-content">
                  <div className="mobile-app-wrap-roadmap-container-item-content-line" />
                  <div className="mobile-app-wrap-roadmap-container-item-content-right">
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-date">
                      2024 Q1
                      <div className="mobile-app-wrap-roadmap-container-item-content-right-date-tag">
                        <span className="mobile-app-wrap-roadmap-container-item-content-right-date-tag-dot" />
                        Already Done
                      </div>
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-title">Alpha Milestone</div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Prototype AI Integration.
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Mini dApps Integration.
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Proof-of-Concept Validation.
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Security Framework.
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-app-wrap-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                <div className="mobile-app-wrap-roadmap-container-item-no">02</div>
                <div className="mobile-app-wrap-roadmap-container-item-content">
                  <div className="mobile-app-wrap-roadmap-container-item-content-line" />
                  <div className="mobile-app-wrap-roadmap-container-item-content-right">
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-date coming">
                      2024 Q2
                      <div className="mobile-app-wrap-roadmap-container-item-content-right-date-tag">
                        <span className="mobile-app-wrap-roadmap-container-item-content-right-date-tag-dot" />
                        Coming Soon
                      </div>
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-title">
                      200k-users Milestone
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Advanced Perception AI
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Intuitive AI Assistant
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Mini dApps Integration
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-app-wrap-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                <div className="mobile-app-wrap-roadmap-container-item-no">03</div>
                <div className="mobile-app-wrap-roadmap-container-item-content">
                  <div className="mobile-app-wrap-roadmap-container-item-content-line" />
                  <div className="mobile-app-wrap-roadmap-container-item-content-right">
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-date">
                      2024 Q3
                      <div className="mobile-app-wrap-roadmap-container-item-content-right-date-tag">
                        <span className="mobile-app-wrap-roadmap-container-item-content-right-date-tag-dot" />
                        Coming Soon
                      </div>
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-title">1m-users Milestone</div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">· Comprehensive SDK</div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Mini dApps Expansion
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      AI Algorithm Refinement
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-app-wrap-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                <div className="mobile-app-wrap-roadmap-container-item-no">04</div>
                <div className="mobile-app-wrap-roadmap-container-item-content">
                  <div className="mobile-app-wrap-roadmap-container-item-content-line" />
                  <div className="mobile-app-wrap-roadmap-container-item-content-right">
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-date">
                      2024 Q4
                      <div className="mobile-app-wrap-roadmap-container-item-content-right-date-tag">
                        <span className="mobile-app-wrap-roadmap-container-item-content-right-date-tag-dot" />
                        Coming Soon
                      </div>
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-title">
                      10m-users Milestone
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Social-Featured DeFi Protocol
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Infrastructure Scaling
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">· AI Enhancements</div>
                  </div>
                </div>
              </div>

              <div className="mobile-app-wrap-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                <div className="mobile-app-wrap-roadmap-container-item-no">05</div>
                <div className="mobile-app-wrap-roadmap-container-item-content">
                  <div className="mobile-app-wrap-roadmap-container-item-content-line" />
                  <div className="mobile-app-wrap-roadmap-container-item-content-right">
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-date">
                      2025 Q1
                      <div className="mobile-app-wrap-roadmap-container-item-content-right-date-tag">
                        <span className="mobile-app-wrap-roadmap-container-item-content-right-date-tag-dot" />
                        Coming Soon
                      </div>
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-title">
                      Diversification & Global Outreach
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">· Global Strategy</div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Diverse Financial Products
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Community Initiatives
                    </div>
                    <div className="mobile-app-wrap-roadmap-container-item-content-right-desc">
                      · Sustainability & Compliance
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileApp;
