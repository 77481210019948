import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/Home';
import JoinWaitlist from './pages/Waitlist';
import UseCases from './pages/Cases';
import AppPage from './pages/app';
import CalendlyPage from './pages/Calendly';
import { isMobile } from './utils/functions';
import MobileHome from './pages/MobileHome';
import MobileWaitlist from './pages/MobileWaitlist';
import MobileCases from './pages/MobileCases';
import MobileApp from './pages/MobileApp';
import MobileCalendly from './pages/MobileCalendly';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={isMobile() ? <MobileHome /> : <HomePage />} />
          <Route path="/joinwaitlist" element={isMobile() ? <MobileWaitlist /> : <JoinWaitlist />} />
          <Route path="/usecases" element={isMobile() ? <MobileCases /> : <UseCases />} />
          <Route path="/app" element={isMobile() ? <MobileApp /> : <AppPage />} />
          <Route path="/calendly" element={isMobile() ? <MobileCalendly /> : <CalendlyPage />} />

          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
