import './index.scss';
import BackgroundImage from '../../assets/images/background_app.png';
import Header from '../../components/Header';
// import ReactPageScroller, { SectionContainer } from 'react-page-scroller';
// import DotIcon from '../../assets/icons/dot.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { notify } from '../../utils/api';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingData from '../../assets/images/loading.json';

const AppPage = (): JSX.Element => {
  var emailRef = useRef<HTMLInputElement>(null);

  const [isShowLoading, setIsShowLoading] = useState(false);

  const onNotify = async () => {
    if (isShowLoading) {
      return;
    }
    // @ts-ignore
    const email = emailRef.current.value;

    if (!email.trim()) {
      toast.error('Email can not be empty.');
      return;
    }

    const reg = new RegExp(/^[A-Za-z0-9-_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
    if (!reg.test(email.trim())) {
      toast.error('Email validate failed.');
      return;
    }

    setIsShowLoading(true);
    try {
      await notify(email.trim());
      toast.success('Successful!');
    } catch (error) {
      console.log('%c⧭', 'color: #00e600', error);
      toast.error('Bad Network.');
    } finally {
      setIsShowLoading(false);
    }
  };

  useEffect(() => {
    AOS.init({
      anchorPlacement: 'bottom-center',
      mirror: true,
      once: true,
      easing: 'ease-in-out',
      delay: 300,
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="app-page">
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              fontSize: '15px',
              minWidth: '200px',
              minHeight: '56px',
              padding: '4px 8px',
              wordBreak: 'break-all',
              fontFamily: 'HarmonyOS Sans SC',
            },
          }}
        />

        {isShowLoading && (
          <div className="overlay">
            <Player autoplay loop src={LoadingData} style={{ height: '300px', width: '300px' }}></Player>
          </div>
        )}

        <Header type="app" />
        <img className="app-page-background" src={BackgroundImage} alt="background" />
        <div className="app-page-wrap">
          <div className="app-page-notify">
            <div className="app-page-notify-title" data-aos="fade-left" data-aos-delay="100">
              Agent that helps you with Web3 Intents
            </div>
            <div className="app-page-notify-sub" data-aos="fade-left" data-aos-delay="600">
              Our AutoAPE.AI platform utilizes advanced AI Agents to streamline
              <br />
              everyday interactions with Web3,
              <br />
              making them more intuitive, efficient, and user-friendly.
              <br />
              <span>Available on iOS and Google Play in April 2024.</span>
            </div>
            <div className="app-page-notify-input" data-aos="fade-in" data-aos-delay="900">
              <input placeholder="*Email" type="text" ref={emailRef} />
              <div className="app-page-notify-input-btn hover-animate" onClick={onNotify}>
                Notify
              </div>
            </div>
          </div>
          <section className="app-page-roadmap">
            <div className="app-page-roadmap-title">Roadmap</div>
            <div className="app-page-roadmap-container">
              <div className="app-page-roadmap-container-top">
                <div className="app-page-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                  <div className="app-page-roadmap-container-item-no">
                    <div className="app-page-roadmap-container-item-no-text">01</div>
                    <div className="app-page-roadmap-container-item-no-line" />
                  </div>
                  <div className="app-page-roadmap-container-item-date">
                    2024 Q1
                    <div className="app-page-roadmap-container-item-date-tag">
                      <span className="app-page-roadmap-container-item-date-tag-dot" />
                      Already Done
                    </div>
                  </div>
                  <div className="app-page-roadmap-container-item-title">Alpha Milestone</div>
                  <div className="app-page-roadmap-container-item-desc">· Prototype AI Integration.</div>
                  <div className="app-page-roadmap-container-item-desc">· Mini dApps Integration.</div>
                  <div className="app-page-roadmap-container-item-desc">· Proof-of-Concept Validation.</div>
                  <div className="app-page-roadmap-container-item-desc">· Security Framework.</div>
                </div>
                <div className="app-page-roadmap-container-item" data-aos="fade-left" data-aos-delay="500">
                  <div className="app-page-roadmap-container-item-no">
                    <div className="app-page-roadmap-container-item-no-text">02</div>
                    <div className="app-page-roadmap-container-item-no-line" />
                  </div>
                  <div className="app-page-roadmap-container-item-date coming">
                    2024 Q2
                    <div className="app-page-roadmap-container-item-date-tag">
                      <span className="app-page-roadmap-container-item-date-tag-dot" />
                      Coming Soon
                    </div>
                  </div>
                  <div className="app-page-roadmap-container-item-title">200k-users Milestone</div>
                  <div className="app-page-roadmap-container-item-desc">· Advanced Perception AI</div>
                  <div className="app-page-roadmap-container-item-desc">· Intuitive AI Assistant</div>
                  <div className="app-page-roadmap-container-item-desc">· Mini dApps Integration</div>
                </div>
                <div className="app-page-roadmap-container-item" data-aos="fade-left" data-aos-delay="900">
                  <div className="app-page-roadmap-container-item-no">
                    <div className="app-page-roadmap-container-item-no-text">03</div>
                    <div className="app-page-roadmap-container-item-no-line" />
                  </div>
                  <div className="app-page-roadmap-container-item-date coming">
                    2024 Q3
                    <div className="app-page-roadmap-container-item-date-tag">
                      <span className="app-page-roadmap-container-item-date-tag-dot" />
                      Coming Soon
                    </div>
                  </div>
                  <div className="app-page-roadmap-container-item-title">1m-users Milestone</div>
                  <div className="app-page-roadmap-container-item-desc">· Comprehensive SDK</div>
                  <div className="app-page-roadmap-container-item-desc">· Mini dApps Expansion</div>
                  <div className="app-page-roadmap-container-item-desc">· AI Algorithm Refinement</div>
                </div>
              </div>
              <div className="app-page-roadmap-container-bottom">
                <div className="app-page-roadmap-container-item" data-aos="fade-left" data-aos-delay="100">
                  <div className="app-page-roadmap-container-item-no">
                    <div className="app-page-roadmap-container-item-no-text">04</div>
                    <div className="app-page-roadmap-container-item-no-line" />
                  </div>
                  <div className="app-page-roadmap-container-item-date coming">
                    2024 Q4
                    <div className="app-page-roadmap-container-item-date-tag">
                      <span className="app-page-roadmap-container-item-date-tag-dot" />
                      Coming Soon
                    </div>
                  </div>
                  <div className="app-page-roadmap-container-item-title">10m-users Milestone</div>
                  <div className="app-page-roadmap-container-item-desc">· Social-Featured DeFi Protocol</div>
                  <div className="app-page-roadmap-container-item-desc">· Infrastructure Scaling</div>
                  <div className="app-page-roadmap-container-item-desc">· AI Enhancements</div>
                </div>
                <div className="app-page-roadmap-container-item last" data-aos="fade-left" data-aos-delay="500">
                  <div className="app-page-roadmap-container-item-no">
                    <div className="app-page-roadmap-container-item-no-text">05</div>
                    <div className="app-page-roadmap-container-item-no-line" />
                  </div>
                  <div className="app-page-roadmap-container-item-date coming">
                    2025 Q1
                    <div className="app-page-roadmap-container-item-date-tag">
                      <span className="app-page-roadmap-container-item-date-tag-dot" />
                      Coming Soon
                    </div>
                  </div>
                  <div className="app-page-roadmap-container-item-title">Diversification & Global Outreach</div>
                  <div className="app-page-roadmap-container-item-desc">· Global Strategy</div>
                  <div className="app-page-roadmap-container-item-desc">· Diverse Financial Products</div>
                  <div className="app-page-roadmap-container-item-desc">· Community Initiatives</div>
                  <div className="app-page-roadmap-container-item-desc">· Sustainability & Compliance</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AppPage;
